import * as React from "react";
import Legend from "./Legend";
import { palettes } from "./Palettes";
export const LandCover = () => {
  const precipitationPalette = palettes.LANDCOVER.split(",");
  const paletteValues = ["Tree", "Shrub", "Grass", "Crop", "Urban", "Bare", "Ice", "Water"];
  const dist_arr = [];
  const length = precipitationPalette.length;

  for (let i = 0; i < length; i++) {
    dist_arr.push(1);
  }
  const legendName = "LANDCOVER";
  return (
    <Legend
      palette={precipitationPalette}
      values={paletteValues}
      legendText={legendName}
      leftProp="42"
      dist_arr={dist_arr}
    />
  );
};
