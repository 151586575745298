export function changeStateName(data) {
  return {
    type: "CHANGE_STATE_NAME",
    payload: { stateName: data },
  };
}

export function changeDistrictName(data) {
  return {
    type: "CHANGE_DISTRICT_NAME",
    payload: { districtName: data },
  };
}
export function changeWardName(data) {
  return {
    type: "CHANGE_WARD_NAME",
    payload: { wardName: data },
  };
}

export function changeClusterNumber(data) {
  return {
    type: "CHANGE_CLUSTER_NUMBER",
    payload: { clusterNumber: data },
  };
}

export function changeClusterNumbers(data) {
  return {
    type: "CHANGE_CLUSTER_NUMBERS",
    payload: { clusterNumbers: data },
  };
}
