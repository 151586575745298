import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Label, Spinner } from "reactstrap";
import { ColumnChart } from "./ColumnChart";
import { TimeSeries } from "./TimeSeries";
import { Auth } from "aws-amplify";
import { LandCover } from "./LandCover";
import { CSVLink } from "react-csv";
import { AiOutlineDownload } from "react-icons/ai";
import { DualAxesChart } from "./DualAxesChart";
import { OlMap } from "../DataLibrary/OlMap";
import Select from "react-select";
import { Pie } from "./Pie";
import { changeStateName } from "../../redux/action";
import { changeDistrictName } from "../../redux/action";
import { changeWardName } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

export const DataLibrary = () => {
  const [states, setStates] = useState([]);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const [wards, setWards] = useState([]);
  const wardName = useSelector((state) => state.wardName);
  const [ndviAnomaly, setNDVIAnomaly] = useState([]);
  const [ndviAnomalyDownload, setNDVIAnomalyDownload] = useState([]);
  const [yearlyRainfall, setYearlyRainfall] = useState([]);
  const [landCover, setLandCover] = useState([]);
  const [seasonality, setSeasonality] = useState([]);
  const [correlationScore, setCorrelationScore] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch("/api/climate/getStateNames", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setStates(data);
        });
      if (stateName !== null) {
        fetch("/api/climate/getDistrictNames?state_name=" + stateName, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setDistricts(data);
          });
        if (districtName !== null) {
          fetch(
            "/api/climate/getWardNames?state_name=" +
            stateName +
            "&district_name=" +
            districtName,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setWards(data);
            });
          if (wardName !== null) {
            fetch(
              "/api/climate/correlationScore?state_name=" +
              stateName +
              "&district_name=" +
              districtName +
              "&ward_name=" +
              wardName,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setCorrelationScore(data["score"]);
              })
              .catch(()=>setCorrelationScore("NA"))
          }
        }
      }
      if (stateName !== null && districtName !== null && wardName !== null) {
        await fetch(
          "/api/climate/landCoverPercent?state_name=" +
          stateName +
          "&district_name=" +
          districtName +
          "&ward_name=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setLandCover(data);
          });

        await fetch(
          "/api/climate/yearlyRainfall?state_name=" +
          stateName +
          "&district_name=" +
          districtName +
          "&ward_name=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            //console.log("rainfalldata",data)
            setYearlyRainfall(data);
          });

        

        
        await fetch(
          "/api/climate/ndviRainfallGraph?state_name=" +
          stateName +
          "&district_name=" +
          districtName +
          "&ward_name=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            
            setSeasonality(data);
          });

        await fetch(
          "/api/climate/anomalyNDVI?state_name=" +
          stateName +
          "&district_name=" +
          districtName +
          "&ward_name=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setNDVIAnomaly(data);
          })
          .catch(()=>setNDVIAnomaly([]))

        await fetch(
          "/api/climate/anomalyNDVIDownload?state_name=" +
          stateName +
          "&district_name=" +
          districtName +
          "&ward_name=" +
          wardName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setNDVIAnomalyDownload(data);
          })
          .catch(()=>setNDVIAnomalyDownload([]))
      }
    }
    genToken();

  }, [stateName, districtName, wardName]);

  let stateObject = [];
  let districtObject = [];
  let wardObject = [];

  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  for (let j in wards) {
    let items = {};
    items["label"] = wards[j];
    items["value"] = wards[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    wardObject.push(json_obj);
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  function isNDVINullMoreThan50Percent(arrayOfObjects) {
    if (!Array.isArray(arrayOfObjects)) {
      throw new Error('Input should be an array');
    }

    const totalObjects = arrayOfObjects.length;
    if (totalObjects === 0) {
      return false;
    }

    let nullCount = 0;

    for (const obj of arrayOfObjects) {
      if (obj.ndvi === null) {
        nullCount++;
      }
    }

    let currentyear = new Date().getFullYear();
    let totalData = 12 * 3 * (currentyear - 2003) 

    if (nullCount > totalObjects / 4 || totalObjects < totalData * 0.75)
      return true
    else
      return false
  }

  const handleNDVIDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/historicalNDVIDownload?state_name=" +
        stateName +
        "&district_name=" +
        districtName +
        "&ward_name=" +
        wardName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${stateName}_${wardName}_Ndvi.xlsx`);

          // Append the link to the document body and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle the error
          console.error("Error:", error);
        });
    }
    genToken();
  };

  const handleChirpsMonthlyDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/monthlyRainfallDownload?state_name=" +
        stateName +
        "&district_name=" +
        districtName +
        "&ward_name=" +
        wardName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${stateName}_${wardName}_Chirps_Monthly_Rainfall.xlsx`);

          // Append the link to the document body and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle the error
          console.error("Error:", error);
        });
    }
    genToken();
  };

  const handleChirpsYearlyDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/yearlyRainfallDownload?state_name=" +
        stateName +
        "&district_name=" +
        districtName +
        "&ward_name=" +
        wardName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${stateName}_${wardName}_Chirps_Yearly_Rainfall.xlsx`);

          // Append the link to the document body and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle the error
          console.error("Error:", error);
        });
    }
    genToken();
  };
  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 80px)",
        }}
      >
        <div className="col-md-6">
          <div className="row m-1">
            <div className="col-md-12">
              <Card
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                }}
              >
                <OlMap ward={wardName} state={stateName} />
                <div className="row justify-content-center mt-1">
                  <div className="col-md-12">
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <LandCover />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Card
            className="m-1"
            body
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              height: "85vh",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-2" style={{ fontSize: "12px" }}>
                <Label>Select State</Label>
                <Select
                  options={stateObject}
                  value={{
                    value: stateName,
                    label: stateName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeStateName(obj["value"]));
                    dispatch(changeDistrictName(null));
                    dispatch(changeWardName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-3" style={{ fontSize: "12px" }}>
                <Label>Select District</Label>
                <Select
                  options={districtObject}
                  value={{
                    value: districtName,
                    label: districtName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeDistrictName(obj["value"]));
                    dispatch(changeWardName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-3" style={{ fontSize: "12px" }}>
                <Label>Select Ward</Label>
                <Select
                  options={wardObject}
                  value={{
                    value: wardName,
                    label: wardName,
                  }}
                  onChange={(obj) => dispatch(changeWardName(obj["value"]))}
                  required
                  styles={customStyles}
                />
              </div>{" "}
            </div>
            {wardName !== null  &&
              landCover.length !== 0 ? (
              <>
                <CardBody className="scroll">
                  <div className="row justify-content-between">
                    <div className="col-md-3">
                      <h5 style={{ fontWeight: "bold" }}>{wardName}</h5>
                    </div>
                    <div className="col-md-3">
                      <h5 style={{ fontWeight: "bold", color: "#ff9933" }}>
                        R <sup>2</sup> Score: {correlationScore}
                      </h5>
                    </div>
                  </div>
                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    Land Cover
                  </CardTitle>

                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <Pie data={landCover} />
                    </div>
                  </div>
                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    CHIRPS Yearly Precipitation{" "}
                    
                      <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer'
                        }}
                        onClick={handleChirpsYearlyDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", marginTop: "4px" }}
                        />
                      </div>
                   
                  </CardTitle>
                  <ColumnChart
                    data={yearlyRainfall}
                    type={"Data"}
                    state={stateName}
                    name={wardName}
                  />
                  {true ? (
                    <>
                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-2"
                      >
                        CHIRPS Monthly Precipitation{" "}
                          <div
                            className="shadow"
                            style={{
                              borderRadius: "50%",
                              borderColor: "#0039E6",
                              backgroundColor: "#0039E6",
                              height: "22px",
                              width: "22px",
                              justifyContent: "center",
                              display: "inline-flex",
                              cursor: 'pointer'
                            }}
                            onClick={handleChirpsMonthlyDownload}
                          >
                            <AiOutlineDownload
                              size={16}
                              style={{ color: "#fff", marginTop: "4px" }}
                            />
                          </div>
                        
                      </CardTitle>
                      <ColumnChart
                        data={seasonality["rainfall"]}
                        type={"Data"}
                        name={wardName}
                      />
                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5"
                      >
                        NDVI Timeseries
                        <div
                          className="shadow m-1"
                          style={{
                            borderRadius: "50%",
                            borderColor: "#0039E6",
                            backgroundColor: "#0039E6",
                            height: "22px",
                            width: "22px",
                            justifyContent: "center",
                            display: "inline-flex",
                            cursor: 'pointer'
                          }}
                          onClick={handleNDVIDownload}
                        >
                          <AiOutlineDownload
                            size={16}
                            style={{ color: "#fff", marginTop: "4px" }}
                          />
                        </div>
                      </CardTitle>
                      {seasonality.length !== 0 && isNDVINullMoreThan50Percent(seasonality["ndvi"]) ? (
                        <CardTitle style={{ textAlign: 'left', border: '1px solid #D3D3D3', padding: '10px' }}>
                          <p>NDVI data is not available, possible reason can be due to :</p>
                          <p> 1. Grassland and Shrubland in this ward is less than 15% of total area.</p>
                          <p> 2. Due to modis ndvi resolution problem.</p>
                          <p> 3. The data available is less than 75% of historical data.</p>
                        </CardTitle>) : (
                        <TimeSeries
                          data={seasonality["ndvi"]}
                          wardName={wardName}
                          type={"ndvi"}
                        />)
                      }

                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5"
                      >
                        Seasonality
                      </CardTitle>

                      <DualAxesChart data={seasonality} wardName={wardName} />

                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5"
                      >
                        NDVI Anomaly
                        <CSVLink
                          data={ndviAnomalyDownload}
                          filename={
                            stateName + "_" + wardName + "_NDVI_Anomaly.csv"
                          }
                        >
                          <div
                            className="shadow m-1"
                            style={{
                              borderRadius: "50%",
                              borderColor: "#0039E6",
                              backgroundColor: "#0039E6",
                              height: "22px",
                              width: "22px",
                              justifyContent: "center",
                              display: "inline-flex",
                            }}
                          >
                            <AiOutlineDownload
                              size={16}
                              style={{ color: "#fff", marginTop: "4px" }}
                            />
                          </div>
                        </CSVLink>
                      </CardTitle>
                      {ndviAnomaly.length === 0 || isNDVINullMoreThan50Percent(ndviAnomaly) ? (
                        <CardTitle style={{ textAlign: 'left', border: '1px solid #D3D3D3', padding: '10px' }}>
                          <p>NDVI Anomaly data is not available, possible reason can be due to :</p>
                          <p> 1. Grassland and Shrubland in this ward is less than 15% of total area.</p>
                          <p> 2. Due to modis ndvi resolution problem.</p>
                          <p> 3. The data available is less than 75% of historical data.</p>
                        </CardTitle>) : (
                      <TimeSeries
                        data={ndviAnomaly}
                        wardName={wardName}
                        type={"anomaly"}
                      />)}
                    </>
                  ) : (
                    <div className="text-center mt-5">
                      <Spinner color="dark" />
                    </div>
                  )}
                </CardBody>
              </>
            ) : (
              <>
                <h6 className="mt-2">Please select ward</h6>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
