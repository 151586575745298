function getUnimodalContent(
  cluster,
  trigger,
  triggerValue,
  exit,
  exitValue,
  maxPayout,
  commercialLoading,
  deductibleLoading,
  premium,
  start,
  end,
  sumInsured,
  rainfall
) {
  return (
    `
    <div style="font-family: Georgia, serif;">
      <p style="text-indent: 0pt; text-align: left" />
      <table cellspacing="0">
        <tr style="height: 20pt">
          <td
            style="
              width: 148pt;
            "
          >
            <p
              class="s1"
              style="
                padding-left: 50pt;
                text-indent: 0pt;
                text-align: left;
              "
            >
            <img width="120" height="40" src="acre.png" />
            </p>
          </td>
        </tr>
      </table>
      <p
        class="s3"
        style="
          padding-left: 204pt;
          text-indent: -195pt;
          line-height: 111%;
          text-align: center;
          font-weight: bold;
        "
      >
        INDEX-BASED LIVESTOCK INSURANCE TERMSHEET FOR TANZANIA
      </p>
      <table
        style="border-collapse: collapse; margin-left: auto;margin-right: auto;"
        cellspacing="0"
      >
        <tr style="height: 26pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Type of Product
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size: 13px;font-style: Calibri;"
            >
              Index-Based Livestock Insurance for Pastoralists
            </p>
          </td>
        </tr>
        <tr style="height: 76pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              The Risk
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              IBLI is a product that is designed to protect pastoralists and their
              livestock against the effects of prolonged forage scarcity. It
              triggers a payment to the pastoralists when the forage level goes to
              levels considered severe, compared to historical conditions over
              time.
            </p>
          </td>
        </tr>
        <tr style="height: 114pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              The Index
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              The index in IBLI is the deviation of cumulative forage availability
              in the insured season. It measures forage conditions over a defined
              time, and it is calculated using a measure of pasture availability
              that is recorded by satellites, called the Normalized Difference
              Vegetation Index (NDVI). The index compares the observed NDVI over a
              particular season, with the observed NDVI over a given historical
              period (for example, in this case, 20 years).
            </p>
          </td>
        </tr>
        <tr style="height: 153pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Trigger
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              The trigger level, <span style="font-weight:bold">` +
    trigger * 100 +
    `</span> th
              PERCENTILE, is the index threshold below which payouts must be made.
                Supposing the forage conditions are ranked from 1 – 100 with one
                being the worst and 100 being the best. The trigger level is then
                set such that if the forage conditions for the current contract
                season are ranked ` +
    trigger * 100 +
    ` and below, the contract will pay out.
                Therefore, the trigger level is set at the <span style="font-weight:bold">` +
    trigger * 100 +
    `</span>th percentile.
            </p>
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              This generally means that the contract will compensate if the forage
              condition falls below the worst <span style="font-weight:bold">` +
    trigger * 100 +
    `</span>th percentile of seasonal pasture
              levels in the contract area.
            </p>
          </td>
        </tr>
        <tr style="height: 141pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold; padding-top: 10pt;"
            >
              Exit
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 11pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              The exit level is the index threshold below which the maximum payout
              (total sum insured) is made. Supposing the forage conditions are
              ranked from the highest to the lowest. The exit level is then set
              such that if the forage conditions for the current season are
              <span style="font-weight:bold;">` +
    exit +
    ` </span>and below, the contract will pay out the
              maximum. 
              The exit is therefore the <span style="font-weight:bold;">` +
    exit +
    ` </span>value.
            </p>
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 4pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              This means that the contract will make the full payment of the total
              sum insured if the forage condition falls below the worst/<span
                style="font-weight:bold"
                >` +
    exit +
    ` </span
              >seasonal pasture levels in the area covered.
            </p>
          </td>
        </tr>
        <tr style="height: 87pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Coverage Period
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 0pt;
                text-align: left;
              "
            >
            This is an annual policy that covers the pastoralists from the effects of drought during the long rains season
            <br />
            Long Rains Season
                <table style="border-collapse: collapse" cellspacing="0">
                <tr style="height: 13pt;font-size: 13px;font-style: Calibri;">
                <td
                    style="
                    width: 97pt;
                    border-top-style: solid;
                    border-top-width: 1pt;
                    border-left-style: solid;
                    border-left-width: 1pt;
                    border-bottom-style: solid;
                    border-bottom-width: 1pt;
                    border-right-style: solid;
                    border-right-width: 1pt;
                    "
                >
                    <p
                    class="s1"
                    style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                    "
                    >
                    UAI
                    </p>
                </td>
                <td
                    style="
                    width: 97pt;
                    border-top-style: solid;
                    border-top-width: 1pt;
                    border-left-style: solid;
                    border-left-width: 1pt;
                    border-bottom-style: solid;
                    border-bottom-width: 1pt;
                    border-right-style: solid;
                    border-right-width: 1pt;
                    "
                >
                    <p
                    class="s1"
                    style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                    "
                    >
                    Start Date
                    </p>
                </td>
                <td
                    style="
                    width: 97pt;
                    border-top-style: solid;
                    border-top-width: 1pt;
                    border-left-style: solid;
                    border-left-width: 1pt;
                    border-bottom-style: solid;
                    border-bottom-width: 1pt;
                    border-right-style: solid;
                    border-right-width: 1pt;
                    "
                >
                    <p
                    class="s1"
                    style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                    "
                    >
                    End Date
                    </p>
                </td>
                </tr>
                <tr style="height: 13pt;font-size: 13px;font-style: Calibri;">
                <td
                    style="
                    width: 97pt;
                    border-top-style: solid;
                    border-top-width: 1pt;
                    border-left-style: solid;
                    border-left-width: 1pt;
                    border-bottom-style: solid;
                    border-bottom-width: 1pt;
                    border-right-style: solid;
                    border-right-width: 1pt;
                    "
                >
                    <p
                    class="s2"
                    style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                    "
                    >
                    ` +
    cluster +
    `
                    </p>
                </td>
                <td
                    style="
                    width: 97pt;
                    border-top-style: solid;
                    border-top-width: 1pt;
                    border-left-style: solid;
                    border-left-width: 1pt;
                    border-bottom-style: solid;
                    border-bottom-width: 1pt;
                    border-right-style: solid;
                    border-right-width: 1pt;
                    "
                >
                    <p
                    class="s2"
                    style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                    "
                    >
                    ` +
    start +
    `
                    </p>
                </td>
                <td
                    style="
                    width: 97pt;
                    border-top-style: solid;
                    border-top-width: 1pt;
                    border-left-style: solid;
                    border-left-width: 1pt;
                    border-bottom-style: solid;
                    border-bottom-width: 1pt;
                    border-right-style: solid;
                    border-right-width: 1pt;
                    "
                >
                    <p
                    class="s2"
                    style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                    "
                    >
                    ` +
    end +
    `
                    
                    </p>
                </td>
                </tr>
            </table>
            </p>
          </td>
        </tr>
        </table>
        <p style="text-indent: 0pt; text-align: left"><br /></p>
        <p style="text-indent: 0pt; text-align: left"><br /></p>
        <table
        style="border-collapse: collapse; margin-left: auto;margin-right: auto;"
        cellspacing="0">
        <tr style="height: 26pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Description of Data
            </p>
            <p
              class="s1"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 12pt;
                text-align: left;
                font-weight: bold;
              "
            >
              Source
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 2pt;
                line-height: 13pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri
              "
            >
              We use the Normalized Difference Vegetation Index (NDVI) data
              (harmonized eMODIS and eVIIRS data) in designing this product. NDVI is a numerical indicator of the degree of greenness
              from a satellite. NDVI values range from 0 to 1. 
              <br/><br/>
              eMODIS data is 250m × 250m NDVI data
              processed by the United States National Aeronautics and Space
              Administration (NASA) and recorded after every 10 days. 
              <br/><br/>
              eMODIS data is available from 2002 to 2022. eVIIRS data product is 375m
              × 375m NDVI data recorded in intervals of 5 days and developed by
                the National Aeronautics and Space Administration (NASA). 
                <br/><br/>
                eVIIRS data is available from 2013 to the present.
                Due to the unavailability of eMODIS data since 2022, the two
              datasets are harmonized to have 20 years of historical NDVI data.
            </p>
          </td>
        </tr>
      </table>
      <table
        style="border-collapse: collapse; margin-left: auto;margin-right: auto;"
        cellspacing="0"
      >
        <tr style="height: 165pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="
                padding-left: 5pt;
                padding-right: 10pt;
                text-indent: 0pt;
                text-align: left;
                font-weight: bold;
              "
            >
              Insurable Livestock Unit
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size: 13px;font-style: Calibri;"
            >
              The standard livestock types for a pastoral herd will be covered
              –i.e., Camels, Cattle, Sheep, and Goats (Shoats).
              For insurance coverage purposes, livestock is converted into a
              standard livestock unit known as
              <span class="s1">Tropical Livestock Unit</span>. The TLU values for
              different livestock are as below:
            </p>
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 16pt;
                text-indent: 0pt;
                text-align: left;
              "
            >
              
              <table style="border-collapse: collapse" cellspacing="0">
                <tr style="height: 13pt">
                  <td
                    style="
                      width: 116pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 TLU = 1 Camel
                    </p>
                  </td>
                  <td
                    style="
                      width: 58pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                    rowspan="4"
                  >
                    <p
                      class="s5"
                      style="text-indent: 0pt; line-height: 13pt; text-align: center"
                    >
                      -
                    </p>
                  </td>
                  <td
                    style="
                      width: 117pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 Camel = 1 TLU
                    </p>
                  </td>
                </tr>
                <tr style="height: 13pt">
                  <td
                    style="
                      width: 116pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 TLU = 0.7 Cow
                    </p>
                  </td>
                  <td
                    style="
                      width: 117pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 Cow = 0.7 TLU
                    </p>
                  </td>
                </tr>
                <tr style="height: 13pt">
                  <td
                    style="
                      width: 116pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 TLU = 10 Goats
                    </p>
                  </td>
                  <td
                    style="
                      width: 117pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 Goat = 0.1 TLU
                    </p>
                  </td>
                </tr>
                <tr style="height: 13pt">
                  <td
                    style="
                      width: 116pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 TLU = 10 Sheep
                    </p>
                  </td>
                  <td
                    style="
                      width: 117pt;
                      border-top-style: solid;
                      border-top-width: 1pt;
                      border-left-style: solid;
                      border-left-width: 1pt;
                      border-bottom-style: solid;
                      border-bottom-width: 1pt;
                      border-right-style: solid;
                      border-right-width: 1pt;
                    "
                  >
                    <p
                      class="s1"
                      style="
                        padding-left: 5pt;
                        text-indent: 0pt;
                        line-height: 12pt;
                        text-align: left;
                        font-weight: bold;
                      "
                    >
                      1 Sheep = 0.1 TLU
                    </p>
                  </td>
                </tr>
              </table>
            </p>
          </td>
        </tr>
        <tr style="height: 102pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Sum Insured (SI)
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 8pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
            The sum insured is the average amount of money the pastoralist will require to buy fodder for 1 TLU during the drought period up to the next wet season. 
            Thus, it is the cost of forage required to sustain the insured livestock in 1 TLU over the dry months within 1 year period.
            </p>
            <p
              class="s2"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
            The SI for the contract is <span class="s1">` +
    sumInsured +
    ` TZS</span>.
            </p>
          </td>
        </tr>
        <tr style="height: 115pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Premium Rate
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size: 13px;
              font-style: Calibri;"
            >
            The premium is the amount paid annually by a pastoralist for every TLU (basically an animal) that they wish to insure. 
            It is the amount the insured pastoralist pays the insurance company for every insured TLU to obtain coverage against the effects of forage scarcity due to drought.
            The premium rate is the cost of insuring an animal per unit of protection or risk covered.
            
            Annual Premium = Premium Rate * Sum Insured.
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left;">
              For this contract, the premium rate is <span style="font-weight:bold">` +
    premium +
    ` %</span>.
            </p>
            <p style="padding-left: 5pt; text-indent: 0pt; text-align: left;">
            Therefore, the Annual Premium is <span style="font-weight:bold">` +
    (premium * sumInsured /100).toFixed(2) +
    ` USD</span>.
            </p>
          </td>
        </tr>
        <tr style="height: 77pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Maximum Payout
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 5pt;
                text-indent: 0pt;
                text-align: justify;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              The maximum payout rate is the maximum percentage of the sum
              insured that can be paid out by the insurance contract in each
              season.
            </p>
            <p
              class="s2"
              style="padding-left: 5pt; text-indent: 0pt; text-align: justify"
            >
              The maximum payout rate for the contract is
              <span style="font-weight:bold">` +
    maxPayout * 100 +
    `%</span>.
            </p>
          </td>
        </tr>
        </table>
        <p style="text-indent: 0pt; text-align: left"><br /></p>
        <table style="border-collapse: collapse; margin-left: auto;margin-right: auto;"
        cellspacing="0">        
        <tr style="height: 102pt">
          <td
            style="
              width: 148pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s1"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-weight: bold"
            >
              Exclusions
            </p>
          </td>
          <td
            style="
              width: 303pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s2"
              style="
                padding-left: 5pt;
                padding-right: 11pt;
                text-indent: 0pt;
                text-align: left;
                font-size: 13px;
                font-style: Calibri;
              "
            >
              This contract only covers the risk of drought. In the event the
              contract is triggered to pay out, the pastoralists in the covered
              area will be compensated to protect their livestock from the effects
              of forage scarcity due to drought.
            </p>
            <p
              class="s2"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size: 13px;font-style: Calibri;"
            >
              Other risks that the livestock is exposed to, for example, diseases
              and theft, are not covered under this contract.
            </p>
          </td>
        </tr>
      </table>
      <p style="text-indent: 0pt; text-align: left"><br /></p>
      <p
        style="
          padding-top: 10pt;
          padding-left: 5pt;
          text-indent: 0pt;
          text-align: center;
        "
      >
        I confirm that I have read and understood the structure and details of the
        above livestock insurance product and agree to this index cover:
      </p>
      <p style="text-indent: 0pt; text-align: left"><br /></p>
      <p style="text-indent: 0pt; text-align: left"><br /></p>
      <table cellspacing="0">
      <tr style="height: 20pt">
        <td
          style="
            width: 148pt;
          "
        >
          <p
            class="s1"
            style="
              padding-left: 50pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
          Client:
          </p>
        </td>
        <td
          style="
            width: 200pt;                  
          "
        >
            <p
              style="
                padding-left: 40pt;
                text-indent: 0pt;
                text-align: right;
              "
            >
            Signature
            </p>
          </td>
          <td
          style="
            width: 200pt;                  
          "
        >
            <p
              style="
                padding-left: 40pt;
                text-indent: 0pt;
                text-align: right;
              "
            >
            Date:
            </p>
          </td>
        </tr>
      </table>
      <p
        class="s7"
        style="
        padding-top: 11pt;
        padding-bottom: 1pt;
        text-indent: 0pt;
        text-align: center;
        font-weight: bold;"
      >
        SUMMARY OF CONTRACT PARAMETERS
      </p>
      <table
        style="border-collapse: collapse; margin-left: 21.5pt"
        cellspacing="0"
      >
        <tr style="height: 27pt">
          <td
            style="
              width: 60pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Cluster/UAI Name
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s7"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Rainfall Pattern
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Trigger Level
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Exit Level
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Maximum Payout
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
            Target Loss Ratio
            </p>
            </td>
          <td
              style="
                width: 30pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-left: 5pt;
                  text-indent: 0pt;
                  line-height: 14pt;
                  text-align: left;
                  font-weight:bold;
                  font-size:11px;
                "
              >
               Commercial Loading
              </p>
            </td>
            <td style="
            width: 30pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          ">
            <p
              class="s6"
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Deductible
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                padding-right: 17pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Trigger Value
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                padding-right: 20pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Exit Value
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                padding-right: 12pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-weight:bold;
                font-size:11px;
              "
            >
              Premium Rate
            </p>
          </td>
        </tr>
        <tr style="height: 27pt">
          <td
            style="
              width: 60pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s9"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
              ` +
    cluster +
    `
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s7"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
              ` +
    rainfall +
    `
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="
                padding-left: 5pt;
                padding-right: 10pt;
                text-indent: 0pt;
                line-height: 14pt;
                text-align: left;
                font-size:11px;
              "
            >
            ` +
    trigger * 100 +
    `th percentile
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
            ` +
    exit +
    `
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
            ` +
    maxPayout * 100 +
    ` %
            </p>
          </td>
          <td
          style="
            width: 30pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
          >
          ` +
  commercialLoading +
  ` %
          </p>
          </td>
          <td
          style="
            width: 30pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
          >
          ` +
  (100 - commercialLoading) +
  ` %
          </p>
          </td>
          <td
          style="
            width: 30pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
          >
          ` +
  deductibleLoading +
  ` %
          </p>
        </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
              ` +
    triggerValue +
    `
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
              ` +
    exitValue +
    `
            </p>
          </td>
          <td
            style="
              width: 30pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s6"
              style="padding-left: 5pt; text-indent: 0pt; text-align: left;font-size:11px;"
            >
              ` +
    premium +
    ` %
            </p>
          </td>
        </tr>
      </table>
      <p style="text-indent: 0pt; text-align: left"><br /></p>
      <p
        class="s7"
        style="
          padding-top: 11pt;
          padding-bottom: 1pt;
          text-indent: 0pt;
          text-align: center;
          font-weight: bold;
        "
      >
        HISTORICAL PAYOUTS FOR ` +
    cluster +
    `
      </p>
    </div>
    `
  );
}

export default getUnimodalContent;
