import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const StackedColumn = (props) => {
  let long = [];
  let short = [];
  let years = [];
  if (props) {
    for (let i in props.data["Long Rains"]) {
      long.push(props.data["Long Rains"][i]["payout"]);
      years.push(props.data["Long Rains"][i]["year"]);
    }
    for (let i in props.data["Short Rains"]) {
      short.push(props.data["Short Rains"][i]["payout"]);
      years.push(props.data["Short Rains"][i]["year"]);
    }
  }
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      categories: years,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: "(%)",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      align: "left",
      x: 70,
      verticalAlign: "top",
      y: 70,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}%<br/>Total: {point.stackTotal}%",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Long Rains",
        data: long,
        color: "#0039E6",
      },
      {
        name: "Short Rains",
        data: short,
        color: "#ff9933",
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
