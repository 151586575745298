import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
export const Column = (props) => {
  let categories = [];
  let data = [];
  if (props) {
    if (props.type === "Data") {
      for (let i in props.data) {
        categories.push(i);
        data.push(props.data[i]);
      }
    } else if (props.type === "Pricing") {
      for (let i in props.data) {
        categories.push(props.data[i]["year"]);
        data.push(props.data[i]["payout"]);
      }
    }
  }
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
      crosshair: true,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: props.yAxis,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0"></td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    exporting: {
      enabled: true,
    },
    series: [
      {
        name: "",
        data: data,
        showInLegend: false,
        color: "#0039E6",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
          },
        },
      ],
    },
  };
  return (
    <div id="chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
