import React, { useState, useEffect, useMemo } from "react";
import { Label, FormGroup, Tooltip } from "reactstrap";
import { Auth } from "aws-amplify";
import MaterialReactTable from "material-react-table";
import Select from "react-select";
import { Box, IconButton } from "@mui/material";
import { AiOutlineDownload } from "react-icons/ai";
import html2pdf from "html2pdf.js";
import getMonitorReportUni from "../../utils/monitoringUni";
import getMonitorReportBimodal from "../../utils/monitoringBimodal";

export const Monitoring = () => {
  const [states, setStates] = useState([]);
  const [stateName, setStateName] = useState("Tanga");
  const [districts, setDistricts] = useState([]);
  const [districtName, setDistrictName] = useState("Tanga");
  const [year, setYear] = useState("2023");
  const [policies, setPolicies] = useState([]);
  const [policyStatus, setPolicyStatus] = useState(false);
  const toggle = () => setPolicyStatus(!policyStatus);
  const [trigger, setTrigger] = useState(false);
  const toggle2 = () => setTrigger(!trigger);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/monitoring/getStateNames", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setStates(data);
        });
      if (stateName !== null) {
        fetch("/api/monitoring/getDistrictNames?state_name=" + stateName, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setDistricts(data);
          });

        if (districtName !== null) {
          fetch(
            "/api/monitoring/monitoringTable?year=" +
              year +
              "&state_name=" +
              stateName +
              "&district_name=" +
              districtName,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setPolicies(data);
            });
        }
      }
    }
    genToken();

  }, [districtName, stateName, year]);

  let years = [{ value: "2023", label: "2023" }];

  let stateObject = [];
  let districtObject = [];

  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  const columns = useMemo(
    () => [
      {
        header: "State",
        accessorKey: "state",
        size: 70,
      },
      {
        header: "District",
        accessorKey: "district",
        size: 80,
      },
      {
        header: "Ward",
        accessorKey: "ward",
        size: 80,
      },
      {
        header: "Total Sum Insured",
        accessorKey: "sum_insured_in_dollars",
        Cell: ({ cell }) =>
          cell
            .getValue()
            .toLocaleString("en-US", { style: "currency", currency: "USD" }),
        size: 50,
      },
      {
        header: "Trigger",
        accessorKey: "trigger_level",
        size: 50,
      },
      {
        header: "Exit",
        accessorKey: "exit_level",
        size: 50,
      },
      {
        header: "NDVI",
        accessorKey: "monthly_ndvi",
        size: 80,
      },
      {
        header: "SR Payout(%)",
        accessorKey: "sr_payout%",
        size: 50,
      },
      {
        header: "LR Payout(%)",
        accessorKey: "lr_payout%",
        size: 50,
      },
      {
        header: "Total Payout(%)",
        accessorKey: "total_payout%",//change this to total_payout%
        size: 50,
      },
      {
        header: " Policy Status",
        accessorKey: "policy_status",
        Header: (
          <>
            <div id="status">Policy Status</div>
            <Tooltip isOpen={policyStatus} target="status" toggle={toggle}>
              Grey: Inactive, Red: Active
            </Tooltip>
          </>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              className="colored-circle"
              style={{
                backgroundColor:
                  cell.getValue() === "Inactive" ? "#808080" : "#00cc00",
                borderColor:
                  cell.getValue() === "Inactive" ? "#808080" : "#00cc00",
                display: "inline-block",
                marginLeft: "5px",
                marginRight: "5px",
                marginBottom: "-2px",
                borderRadius: "100%",
                borderStyle: "solid",
                borderWidth: "0.5px",
                height: "15px",
                width: "15px",
              }}
            />
          );
        },
        size: 50,
      },
      {
        header: " Trigger Status",
        accessorKey: "trigger_status",
        Header: (
          <>
            <div id="trigger">Trigger Status</div>
            <Tooltip isOpen={trigger} target="trigger" toggle={toggle2}>
              Grey: No, Red: Yes
            </Tooltip>
          </>
        ),
        Cell: ({ cell }) => {
          return (
            <span
              className="colored-circle"
              style={{
                backgroundColor:
                  cell.getValue() === "No" ? "#808080" : "#ff0000",
                borderColor: cell.getValue() === "No" ? "#808080" : "#ff0000",
                display: "inline-block",
                marginLeft: "5px",
                marginRight: "5px",
                marginBottom: "-2px",
                borderRadius: "100%",
                borderStyle: "solid",
                borderWidth: "0.5px",
                height: "15px",
                width: "15px",
              }}
            />
          );
        },
        size: 50,
      },
    ],
    // eslint-disable-next-line
    [policyStatus, trigger]
  );
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  
  const handleDownload = (row) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      let input = null;

      fetch(
        "/api/monitoring/termsheet?year=" +
          year +
          "&state_name=" +
          stateName +
          "&district_name=" +
          districtName +
          "&ward_name=" +
          row["original"]["ward"],
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (row["original"]["modal_type"] === "Unimodal") {
            input = getMonitorReportUni(
              row["original"]["ward"],
              row["original"]["trigger_level"],
              data["trigger value"],
              row["original"]["exit_level"],
              data["exit value"],
              data["maximum payout"],
              data["premium"],
              data["lr_start_date"],
              data["lr_end_date"],
              row["original"]["sum_insured_in_dollars"],
              "Long Rains"
            );
          } else if (row["original"]["modal_type"] === "Bimodal") {
            input = getMonitorReportBimodal(
              row["original"]["ward"],
              row["original"]["trigger_level"],
              data["trigger value"],
              row["original"]["exit_level"],
              data["exit value"],
              data["maximum payout"],
              data["premium"],
              data["lr_start_date"],
              data["lr_end_date"],
              data["sr_start_date"],
              data["sr_end_date"],
              row["original"]["sum_insured_in_dollars"],
              "Long Rains"
            );
          }
          let div = document.createElement("div");
          div.innerHTML = input;
          let opt = {
            margin: 1,
            filename:
              stateName + "_" + row["original"]["ward"] + "_Monitoring.pdf",
            html2canvas: { scale: 2 },
          };
          html2pdf().set(opt).from(div).save();
        });
    }
    genToken();

  };
  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          backgroundColor: "#fff",
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div className="row justify-content-center mt-2">
          <div className="col-md-11">
            <div className="row justify-content-between">
              <div className="col-md-7 mt-4" style={{ textAlign: "left" }}>
                <h5>Cluster trigger monitoring</h5>
              </div>
              <div className="col-md-4" style={{ fontSize: "10px" }}>
                <div className="row">
                  <div className="col-md-3">
                    <Label>State</Label>
                    <FormGroup>
                      <Select
                        options={stateObject}
                        value={{
                          value: stateName,
                          label: stateName,
                        }}
                        onChange={(obj) => {
                          setStateName(obj["value"]);
                          setDistrictName(null);
                          setPolicies([]);
                        }}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <Label>District</Label>
                    <FormGroup>
                      <Select
                        options={districtObject}
                        value={{
                          value: districtName,
                          label: districtName,
                        }}
                        onChange={(obj) => {
                          setDistrictName(obj["value"]);
                          setPolicies([]);
                        }}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-3">
                    <Label>Year</Label>
                    <FormGroup>
                      <Select
                        options={years}
                        value={{
                          value: year,
                          label: year,
                        }}
                        onChange={(obj) => setYear(obj["value"])}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            {policies.length !== 0 ? (
              <MaterialReactTable
                columns={columns}
                data={policies}
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableRowActions
                positionActionsColumn="last"
                initialState={{
                  density: "compact",
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontSize: "10px",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    fontSize: "11px",
                    zIndex: "auto",
                  },
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [5, 10],
                  showFirstButton: false,
                  showLastButton: false,
                }}
                muiTopToolbarProps={{
                  sx: {
                    zIndex: "auto",
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <IconButton
                      onClick={() => {
                        handleDownload(row);
                      }}
                    >
                      <AiOutlineDownload style={{ color: "#0039e6" }} />
                    </IconButton>
                  </Box>
                )}
              />
            ) : (
              "No Data Available"
            )}
          </div>
        </div>
      </div>
    </>
  );
};
