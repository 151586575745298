import React from "react";
import {
    CardTitle,
} from "reactstrap";

export const Pallete = (props) => {
    const palleteColors = {
        1: '#8DD3C7',//1 and 13 color are same check
        2: '#FFFFB3',
        3: '#BEBADA',
        4: '#FB8072',
        5: '#80B1D3',
        6: '#fdb462',
        7: '#b3de69',
        8: '#fccde5',
        9: '#d9d9d9',
        10: '#bc80bd',
        11: '#ccebc5',
        12: '#ffff50',
        13: '#8dd3c7',
        14: '#1f78b4',
        15: '#33a02c',
        16: '#e31a1c',
        17: '#ff7f00',
        18: '#0ecdd6',
        19: '#6a3d9a',
        //20: '#b15928'
    }

    return (
        <div style={{ marginTop: "40px" }} >
            {Object.entries(palleteColors).map(([key, value]) => {
                if (props.state === "Morogoro" && key < 16) {
                    return (
                        <div className="row">
                            <div className="col-md-2" style={{ height: "20px", width: "40px", backgroundColor: value, border: `1px solid black` }}></div>
                            <div className="col"><CardTitle tag="h7" style={{color:"#000"}}>cluster {key}</CardTitle></div>
                        </div>
                    )
                }
                if (props.state === "Tanga") {
                    return (
                        <div className="row">
                            <div className="col-md-2" style={{ height: "20px", width: "40px", backgroundColor: value, border: `1px solid black` }}></div>
                            <div className="col">
                                <CardTitle tag="h7" style={{color:"#000"}}>
                                    cluster {key}
                                </CardTitle>
                            </div>
                        </div>)
                }
                else
                    return ('')
            }
            )}
        </div>

    );

}
