import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Button,
  Spinner,
} from "reactstrap";
import "../../styles.css";
import Select from "react-select";
import { FiCheck } from "react-icons/fi";
import { AiOutlineDownload } from "react-icons/ai";
import { Auth } from "aws-amplify";
import { Column } from "./Column";
import { CSVLink } from "react-csv";
import html2pdf from "html2pdf.js";
import getBimodalContent from "../../utils/biModal";
import getUnimodalContent from "../../utils/uniModal";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import { StackedColumn } from "./StackedColumn";
import { useDispatch, useSelector } from "react-redux";
import { changeClusterNumber, changeClusterNumbers } from "../../redux/action";
import "../../switch2.css";
import { useNavigate } from "react-router-dom";
import { Pallete } from "./Pallete";
import { SimpleDialog } from "./SimpleDialog";
import Papa from 'papaparse';


export const ClusterPricing = () => {
  const [triggerLevel, setTriggerLevel] = useState("0.25");
  const [exitLevel, setExitLevel] = useState("5");
  const [exitLabel, setExitLabel] = useState("5th Percentile");
  const [maxPayout, setMaxPayout] = useState("1");
  const [longStartDate, setLongStartDate] = useState(new Date('2023-03-01'));
  const [longEndDate, setLongEndDate] = useState(new Date('2023-06-30'));
  const [shortStartDate, setShortStartDate] = useState(new Date('2023-10-01'));
  const [shortEndDate, setShortEndDate] = useState(new Date('2023-12-31'));

  const [payoutAnalysis, setPayoutAnalysis] = useState([]);
  const [bulkPayoutAnalysis, setBulkPayoutAnalysis] = useState([]);
  const [summaryTrigger, setSummaryTrigger] = useState([]);
  const [summaryExit, setSummaryExit] = useState([]);
  const [monthsRange, setMonthsRange] = useState([]);
  const [seasonWeightage, setSeasonWeightage] = useState([]);

  const [summaryDownload, setSummaryDownload] = useState([]);
  const [sumInsured, setSumInsured] = useState(120);
  const [states, setStates] = useState([]);
  //const stateName = useSelector((state) => state.stateName);
  const [stateName, changeStateName] = useState('Tanga');
  const dispatch = useDispatch();
  const clusterNumbers = useSelector((state) => state.clusterNumbers);
  const clusterNumber = useSelector((state) => state.clusterNumber);
  const [modal, setModal] = useState(null);
  let [loading, setLoading] = useState(true);
  let [clusterImage, setClusterImage] = useState('Tanga_Cluster.png')
  let [numberOfWards, setNumberOfWards] = useState(null);
  let [deductible, setDeductible] = useState(0);
  let [commercialLoading, setcommercialLoading] = useState(100);
  let [deductibleLoading, setDeductibleLoading] = useState(0);
  let [open, setOpen] = useState(false);
  let options = ['NDVI', 'Rainfall', 'Landcover'];
  let [selectedValue, setSelectedValue] = useState(options[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    if (value === "NDVI")
      handleNDVIDownload()
    else if (value === "Rainfall")
      handleRainfallDownload()
    else if (value === "Landcover")
      handleLandCoverDownload()
  };


  const navigate = useNavigate();
  let trigger_options = [
    { value: "0.1", label: "0.1" },
    { value: "0.15", label: "0.15" },
    { value: "0.2", label: "0.2" },
    { value: "0.25", label: "0.25" },
    { value: "0.3", label: "0.3" },
    { value: "0.35", label: "0.35" },
    { value: "0.4", label: "0.4" },
    { value: "0.45", label: "0.45" },
    { value: "0.5", label: "0.5" },
    { value: "0.55", label: "0.55" },
    { value: "0.6", label: "0.6" },
    { value: "0.65", label: "0.65" },
    { value: "0.7", label: "0.7" },
    { value: "0.75", label: "0.75" },
    { value: "0.8", label: "0.8" },
    { value: "0.85", label: "0.85" },
    { value: "0.9", label: "0.9" },
    { value: "0.95", label: "0.95" },
    { value: "1", label: "1" },
  ];
  let exit_options = [
    { value: "0", label: "Minimum" },
    { value: "1", label: "1st Percentile" },
    { value: "5", label: "5th Percentile" },
    { value: "10", label: "10th Percentile" },
  ];
  let max_payout_options = [
    { value: "0.5", label: "0.5" },
    { value: "0.6", label: "0.6" },
    { value: "0.7", label: "0.7" },
    { value: "0.8", label: "0.8" },
    { value: "0.9", label: "0.9" },
    { value: "1", label: "1" },
  ];

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      if (stateName !== null) {
        fetch("/api/cluster/climate/getClusterNumbers?state_name=" + stateName, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            dispatch(changeClusterNumbers(data));
          });
      }
    }
    genToken()
  }, [dispatch, stateName])

  useEffect(() => {
    setClusterImage(`${stateName}_Cluster.png`)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/cluster/climate/getStateNames", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setStates(data);
        });
      //change no of wards
      if (clusterNumber !== null) {
        fetch('/api/cluster/payout/noOfWards?state_name=' + stateName + '&cluster_number=' + clusterNumber,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setNumberOfWards(data)
          })
          .catch(() => setNumberOfWards(null))
      }
      if (stateName !== null) {
        fetch("/api/cluster/climate/getClusterNumbers?state_name=" + stateName + "&cluster_number=" + clusterNumber, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            dispatch(changeClusterNumbers(data));
          });
      }
      if (clusterNumber !== null) {
        fetch("/api/cluster/payout/modalType?state_name=" + stateName + "&cluster_number=" + clusterNumber, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.text())
          .then((data) => {
            setModal(data);
          });
        fetch("/api/cluster/payout/defaultDate?state_name=" + stateName + "&cluster_number=" + clusterNumber, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setLongStartDate(new Date(data["lr_start_date"]));
            setLongEndDate(new Date(data["lr_end_date"]));
            if (modal === "Bimodal") {
              setShortStartDate(new Date(data["sr_start_date"]));
              setShortEndDate(new Date(data["sr_end_date"]));
            }
          });
      }
    }
    genToken();

  }, [clusterNumber, stateName, modal, dispatch]);


  useEffect(() => {
    setLoading(false);
    setSummaryExit([]);
    setSummaryTrigger([]);
    setMonthsRange([]);
    setSeasonWeightage([]);

    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      if (
        stateName !== null &&
        triggerLevel !== null &&
        exitLevel !== null &&
        maxPayout !== null &&
        longStartDate !== null &&
        longEndDate !== null
      ) {
        if (
          modal === "Bimodal" &&
          shortStartDate !== null &&
          shortEndDate !== null
        ) {
          if (longStartDate < longEndDate && shortStartDate < shortEndDate) {
            await fetch(
              "/api/cluster/payout/payoutPremium?maximum_payout=" +
              maxPayout +
              "&trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&state_name=" +
              stateName +
              "&cluster_number=" +
              clusterNumber +
              "&modal_type=" +
              modal +
              "&lr_start_date=" +
              moment(longStartDate).format("YYYY-MM-DD") +
              "&lr_end_date=" +
              moment(longEndDate).format("YYYY-MM-DD") +
              "&sr_start_date=" +
              moment(shortStartDate).format("YYYY-MM-DD") +
              "&sr_end_date=" +
              moment(shortEndDate).format("YYYY-MM-DD") +
              "&deductible=" + deductible +
              "&commercial_loading=" + commercialLoading,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
              .then((response) => {
                if (response.status >= 400) {
                  Store.addNotification({
                    title: "Agtuall",
                    message: "No data available for the selected cluster",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true,
                    },
                  });
                }
                return response.json();
              })
              .then(
                (data) => {
                  if (data !== undefined) {
                    setPayoutAnalysis(data);
                  } else {
                    
                    setPayoutAnalysis([]);
                    setSummaryDownload([]);
                    setSummaryExit([]);
                    setMonthsRange([]);
                    setSummaryTrigger([]);
                    setSeasonWeightage([]);
                    setBulkPayoutAnalysis([])
                  }
                },
                () => {
                  setLoading(true);
                }
              );

            await fetch(
              "/api/cluster/payout/summary?trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&state_name=" +
              stateName +
              "&cluster_number=" +
              clusterNumber +
              "&modal_type=" +
              modal +
              "&lr_start_date=" +
              moment(longStartDate).format("YYYY-MM-DD") +
              "&lr_end_date=" +
              moment(longEndDate).format("YYYY-MM-DD") +
              "&sr_start_date=" +
              moment(shortStartDate).format("YYYY-MM-DD") +
              "&sr_end_date=" +
              moment(shortEndDate).format("YYYY-MM-DD"),
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setMonthsRange(data["No of months"]);
                setSummaryTrigger(data["trigger"]);
                setSummaryExit(data["exit"]);
                setSeasonWeightage(data["Weightage"]);
              });
            setLoading(true);

            await fetch(
              "/api/cluster/payout/summaryDownload?maximum_payout=" +
              maxPayout +
              "&trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&state_name=" +
              stateName +
              "&cluster_number=" +
              clusterNumber +
              "&modal_type=" +
              modal +
              "&lr_start_date=" +
              moment(longStartDate).format("YYYY-MM-DD") +
              "&lr_end_date=" +
              moment(longEndDate).format("YYYY-MM-DD") +
              "&sr_start_date=" +
              moment(shortStartDate).format("YYYY-MM-DD") +
              "&sr_end_date=" +
              moment(shortEndDate).format("YYYY-MM-DD"),
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setSummaryDownload(data);
              });

            if (longStartDate < longEndDate && shortStartDate < shortEndDate) {
              await fetch(
                "/api/cluster/payout/bulkPayoutPremium?maximum_payout=" +
                maxPayout +
                "&trigger=" +
                triggerLevel +
                "&exit=" +
                exitLevel +
                "&state_name=" +
                stateName +
                "&modal_type=" +
                modal +
                "&lr_start_date=" +
                moment(longStartDate).format("YYYY-MM-DD") +
                "&lr_end_date=" +
                moment(longEndDate).format("YYYY-MM-DD") +
                "&sr_start_date=" +
                moment(shortStartDate).format("YYYY-MM-DD") +
                "&sr_end_date=" +
                moment(shortEndDate).format("YYYY-MM-DD") +
                "&deductible=" + deductible +
                "&commercial_loading=" + commercialLoading,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                  },
                }
              )
                .then((response) => {
                  if (response.status >= 400) {
                    Store.addNotification({
                      title: "Agtuall",
                      message: "No data available for the selected cluster",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 3000,
                        onScreen: true,
                      },
                    });
                  }
                  return response.json();
                })
                .then(
                  (data) => {
                    if (data !== undefined) {
                      setBulkPayoutAnalysis(data);
                    } else {
                      
                      setPayoutAnalysis([]);
                      setSummaryDownload([]);
                      setSummaryExit([]);
                      setMonthsRange([]);
                      setSummaryTrigger([]);
                      setSeasonWeightage([]);
                      setBulkPayoutAnalysis([])
                    }
                  },
                  (err) => {
                    setLoading(true);
                  }
                );
            }
          } else {
            Store.addNotification({
              title: "Agtuall",
              message: "Check the dates",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
            setLoading(true);
          }
        } else if (modal === "Unimodal") {
          await fetch(
            "/api/cluster/payout/payoutPremium?maximum_payout=" +
            maxPayout +
            "&trigger=" +
            triggerLevel +
            "&exit=" +
            exitLevel +
            "&state_name=" +
            stateName +
            "&cluster_number=" +
            clusterNumber +
            "&modal_type=" +
            modal +
            "&lr_start_date=" +
            moment(longStartDate).format("YYYY-MM-DD") +
            "&lr_end_date=" +
            moment(longEndDate).format("YYYY-MM-DD") +
            "&deductible=" + deductible +
            "&commercial_loading=" + commercialLoading,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => {
              if (!response.ok) {
                Store.addNotification({
                  title: "Agtuall",
                  message: "No data available for the selected cluster",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true,
                  },
                });
              }
              else return response.json();
            })
            .then((data) => {
              if (data !== undefined) {
                setPayoutAnalysis(data);
              } else {
                
                setPayoutAnalysis([]);
                setSummaryDownload([]);
                setSummaryExit([]);
                setSummaryTrigger([]);
                setSeasonWeightage([]);
                setBulkPayoutAnalysis([])
              }
            });

          await fetch(
            "/api/cluster/payout/summary?trigger=" +
            triggerLevel +
            "&exit=" +
            exitLevel +
            "&state_name=" +
            stateName +
            "&cluster_number=" +
            clusterNumber +
            "&modal_type=" +
            modal +
            "&lr_start_date=" +
            moment(longStartDate).format("YYYY-MM-DD") +
            "&lr_end_date=" +
            moment(longEndDate).format("YYYY-MM-DD"),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setMonthsRange(data["No of months"]);
              setSummaryTrigger(data["trigger"]);
              setSummaryExit(data["exit"]);
            });
          setLoading(true);

          await fetch(
            "/api/cluster/payout/summaryDownload?maximum_payout=" +
            maxPayout +
            "&trigger=" +
            triggerLevel +
            "&exit=" +
            exitLevel +
            "&state_name=" +
            stateName +
            "&cluster_number=" +
            clusterNumber +
            "&modal_type=" +
            modal +
            "&lr_start_date=" +
            moment(longStartDate).format("YYYY-MM-DD") +
            "&lr_end_date=" +
            moment(longEndDate).format("YYYY-MM-DD"),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setSummaryDownload(data);
            });
          await fetch(
            "/api/cluster/payout/bulkPayoutPremium?maximum_payout=" +
            maxPayout +
            "&trigger=" +
            triggerLevel +
            "&exit=" +
            exitLevel +
            "&state_name=" +
            stateName +
            "&modal_type=" +
            modal +
            "&lr_start_date=" +
            moment(longStartDate).format("YYYY-MM-DD") +
            "&lr_end_date=" +
            moment(longEndDate).format("YYYY-MM-DD") +
            "&deductible=" + deductible +
            "&commercial_loading=" + commercialLoading,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => {
              if (!response.ok) {
                Store.addNotification({
                  title: "Agtuall",
                  message: "No data available for the selected cluster",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true,
                  },
                });
              }
              else return response.json();
            })
            .then((data) => {
              if (data !== undefined) {
                setBulkPayoutAnalysis(data);
              } else {
                
                setPayoutAnalysis([]);
                setSummaryDownload([]);
                setSummaryExit([]);
                setSummaryTrigger([]);
                setSeasonWeightage([]);
                setBulkPayoutAnalysis([])
              }
            });
        }
      }
    }
    genToken();

    // eslint-disable-next-line
  }, [
    stateName,
    clusterNumber,
    triggerLevel,
    exitLevel,
    maxPayout,
    modal,
    longStartDate,
    longEndDate,
    shortStartDate,
    shortEndDate,
    deductibleLoading,
  ]);

  const handleNDVIDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/cluster/payout/bulkNDVIDownload",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Check if the response contains the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        if (!contentDisposition || !contentDisposition.includes('attachment')) {
          throw new Error('Invalid or missing Content-Disposition header');
        }

        // Get the filename from the 'Content-Disposition' header
        const filename = 'NDVI (modified modis + eviirs).zip';

        // Assume that 'data' is a zip file binary data
        return response.blob().then((data) => ({ data, filename }));
      })
      .then(({ data, filename }) => {
        // Create a link element and trigger the download
        const blob = new Blob([data], { type: 'application/zip' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(a.href);

      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
    }
    genToken();
  };

  const handleRainfallDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/cluster/payout/bulkChirpsMonthlyDownload",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Check if the response contains the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        if (!contentDisposition || !contentDisposition.includes('attachment')) {
          throw new Error('Invalid or missing Content-Disposition header');
        }

        // Get the filename from the 'Content-Disposition' header
        const filename = 'Chirps_Monthly_Rainfall.zip';

        // Assume that 'data' is a zip file binary data
        return response.blob().then((data) => ({ data, filename }));
      })
      .then(({ data, filename }) => {
        // Create a link element and trigger the download
        const blob = new Blob([data], { type: 'application/zip' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(a.href);

      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
    }
    genToken();
  };

  const handleLandCoverDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/cluster/payout/bulkLandCoverDownload",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Landcover.csv`);

          // Append the link to the document body and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    genToken();
  };

  const downloadPdfDocument = () => {
    let input = null;
    if (modal === "Unimodal") {
      input = getUnimodalContent(
        clusterNumber,
        triggerLevel,
        summaryTrigger["Long Rains"],
        exitLabel,
        summaryExit["Long Rains"],
        maxPayout,
        commercialLoading,
        deductible,
        payoutAnalysis["premium"],
        moment(longStartDate).format("YYYY-MM-DD"),
        moment(longEndDate).format("YYYY-MM-DD"),
        sumInsured,
        "Long Rains"
      );
    } else if (modal === "Bimodal") {
      input = getBimodalContent(
        clusterNumber,
        triggerLevel,
        summaryTrigger["Long Rains"] + ", " + summaryTrigger["Short Rains"],
        exitLabel,
        summaryExit["Long Rains"] + ", " + summaryExit["Short Rains"],
        maxPayout,
        commercialLoading,
        deductible,
        payoutAnalysis["premium"],
        moment(longStartDate).format("YYYY-MM-DD"),
        moment(longEndDate).format("YYYY-MM-DD"),
        moment(shortStartDate).format("YYYY-MM-DD"),
        moment(shortEndDate).format("YYYY-MM-DD"),
        sumInsured,
        modal,
        modal === "Bimodal" ? "Long Rains, Short Rains" : "Long Rains"
      );
    }

    let div = document.createElement("div");
    div.innerHTML = input;
    let element = document.getElementById("chart");
    let clone = element.cloneNode(true);
    div.appendChild(clone);
    let opt = {
      margin: 1,
      filename: stateName + "_" + clusterNumber + "_Termsheet.pdf",
      html2canvas: { scale: 2 },
    };
    html2pdf().set(opt).from(div).save();
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  let stateObject = [];
  let clusterObject = [];

  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in clusterNumbers) {
    let items = {};
    items["label"] = clusterNumbers[j];
    items["value"] = clusterNumbers[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    clusterObject.push(json_obj);
  }

  // Custom validation to allow only decimals with two decimal places
  const isValidDecimal = (inputValue) => {
    const decimalRegex = /^(?:0|[1-9]\d{0,1})(\.\d{0,2})?$|^100$/;
    const value = parseFloat(inputValue);
    return inputValue.match(decimalRegex) && value >= 0 && value <= 100;
  };

  // Custom validation to allow only decimals with two decimal places and greater than zero
  const isValidDecimalGreaterThanZero = (inputValue) => {
    const decimalRegex = /^(?:0|[1-9]\d{0,1})(\.\d{0,2})?$|^100$/;
    const value = parseFloat(inputValue);
    return inputValue.match(decimalRegex) && value > 0 && value <= 100;
  };

  const handleDeductibleChange = (event) => {
    let newValue = event.target.value;

    if (newValue === '') {
      // Handle the case where the input is empty (e.g., user deleted all characters)
      setDeductible('');
    } else if (isValidDecimal(newValue)) {
      setDeductible(newValue);
    }
  };

  const handleLoadingChange = (event) => {
    let newValue = event.target.value;

    if (newValue === '') {
      // Handle the case where the input is empty (e.g., user deleted all characters)
      setcommercialLoading('');
    } else if (isValidDecimalGreaterThanZero(newValue)) {
      setcommercialLoading(newValue);
    }
  };

  const handleLoadingDeduction = () => {
    setDeductibleLoading(deductibleLoading + 1)
  }

  const handleRegionPayoutDownload = async () => {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
    await fetch(
      "/api/cluster/payout/bulkPayoutPremiumCreate?maximum_payout=" +
      maxPayout +
      "&trigger=" +
      triggerLevel +
      "&exit=" +
      exitLevel +
      "&state_name=" +
      stateName +
      "&modal_type=" +
      modal +
      "&lr_start_date=" +
      moment(longStartDate).format("YYYY-MM-DD") +
      "&lr_end_date=" +
      moment(longEndDate).format("YYYY-MM-DD") +
      "&sr_start_date=" +
      moment(shortStartDate).format("YYYY-MM-DD") +
      "&sr_end_date=" +
      moment(shortEndDate).format("YYYY-MM-DD") +
      "&deductible=" + deductible +
      "&commercial_loading=" + commercialLoading,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${stateName}_PayoutAnalysis.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const handlePremium = async () => {
    const {
      accessToken: { jwtToken },
    } = await Auth.currentSession();
    let api = "";
    if (modal === "Unimodal") {
      api = "/api/payout/payoutPremium?maximum_payout=" +
        maxPayout +
        "&trigger=" +
        triggerLevel +
        "&exit=" +
        exitLevel +
        "&state_name=" +
        stateName +
        "&cluster_number=" +
              clusterNumber +
        "&modal_type=" +
        modal +
        "&lr_start_date=" +
        moment(longStartDate).format("YYYY-MM-DD") +
        "&lr_end_date=" +
        moment(longEndDate).format("YYYY-MM-DD") +
        "&deductible=" + deductible +
        "&commercial_loading=" + commercialLoading;
    }
    else {
      api = "/api/payout/payoutPremium?maximum_payout=" +
        maxPayout +
        "&trigger=" +
        triggerLevel +
        "&exit=" +
        exitLevel +
        "&state_name=" +
        stateName + "&state_name=" +
        "&cluster_number=" +
        clusterNumber +
        "&modal_type=" +
        modal +
        "&lr_start_date=" +
        moment(longStartDate).format("YYYY-MM-DD") +
        "&lr_end_date=" +
        moment(longEndDate).format("YYYY-MM-DD") +
        "&sr_start_date=" +
        moment(shortStartDate).format("YYYY-MM-DD") +
        "&sr_end_date=" +
        moment(shortEndDate).format("YYYY-MM-DD") +
        "&deductible=" + deductible +
        "&commercial_loading=" + commercialLoading;
    }

    await fetch(
      api,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data)
        const csv = Papa.unparse(data['csvData']);
        //console.log(csv)
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `${stateName}_${clusterNumber} Historical Payout Analysis`;
        document.body.appendChild(a);

        // Trigger a click on the anchor to initiate download
        a.click();

        // Remove the anchor from the DOM
        document.body.removeChild(a);
      })
      .catch(() => {
        Store.addNotification({
          title: "Agtuall",
          message: "No Data Available",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
  }


  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div
          className="col-md-3"
          style={{
            backgroundColor: "#E6ECFF",
            marginBottom: "10px",
            borderColor: "#f5f5f5",
          }}
        >
          <div className="row justify-content-center mt-3 mb-3">
            <div className="col-md-12">
              {modal === "Unimodal" ? (
                <h6>Product Parameters {"("}Unimodal{")"}</h6>
              ) : (
                <h6>Product Parameters {" "}{"("}Bimodal{")"}</h6>
              )}
            </div>
          </div>
          <div className="justify-content-center mb-3">
            <div className="switches-container">
              <input
                type="radio"
                id="switchRobusta"
                name="switchPlan"
                value="Ward"
                onClick={() => navigate("/pricing")}

              />
              <input
                type="radio"
                id="switchArabica"
                name="switchPlan"
                value="Cluster"
                defaultChecked
                onClick={() => navigate("/clusterPricing")}

              />
              <label htmlFor="switchRobusta">Ward</label>
              <label htmlFor="switchArabica">Cluster</label>
              <div className="switch-wrapper">
                <div className="switch">
                  <div>Ward</div>
                  <div>Cluster</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center m-1">
            <div className="col-md-4" style={{ fontSize: "10px" }}>
              <Label>Select State</Label>
              <Select
                options={stateObject}
                value={{
                  value: stateName,
                  label: stateName,
                }}
                onChange={(obj) => {
                  //dispatch(changeStateName(obj["value"]));
                  changeStateName(obj["value"])
                  dispatch(changeClusterNumber(null));
                  setPayoutAnalysis([]);
                  setBulkPayoutAnalysis([])
                  setSummaryExit([]);
                  setSummaryTrigger([]);
                  setMonthsRange([]);
                  setSeasonWeightage([]);
                  setShortStartDate(null);
                  setShortEndDate(null);
                }}
                required
                styles={customStyles}
              />
            </div>{" "}
            <div className="col-md-4" style={{ fontSize: "10px" }}>
              <Label>Select Cluster</Label>
              <Select
                options={clusterObject}
                value={{
                  value: clusterNumber,
                  label: clusterNumber,
                }}
                onChange={(obj) => {
                  dispatch(changeClusterNumber(obj["value"]));
                }}
                required
                styles={customStyles}
              />
            </div>{" "}
          </div>
          <div className="row justify-content-center m-2">
            <div
              className="col-md-4"
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <Label>Trigger Level</Label>
              <FormGroup>
                <Select
                  options={trigger_options}
                  value={{
                    value: triggerLevel !== null ? triggerLevel : "",
                    label: triggerLevel !== null ? triggerLevel : "",
                  }}
                  onChange={(obj) => setTriggerLevel(obj["value"])}
                  required
                  styles={customStyles}
                />
              </FormGroup>
            </div>
            <div
              className="col-md-4"
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <Label>Exit Level</Label>
              <FormGroup>
                <Select
                  options={exit_options}
                  value={{
                    value: exitLevel !== null ? exitLevel : "",
                    label: exitLabel !== null ? exitLabel : "",
                  }}
                  onChange={(obj) => {
                    setExitLevel(obj["value"]);
                    setExitLabel(obj["label"]);
                  }}
                  required
                  styles={customStyles}
                />
              </FormGroup>
            </div>
            <div
              className="col-md-4"
              style={{ fontSize: "10px", textAlign: "center" }}
            >
              <Label>Max Payout</Label>
              <FormGroup>
                <Select
                  options={max_payout_options}
                  value={{
                    value: maxPayout !== null ? maxPayout : "",
                    label: maxPayout !== null ? maxPayout : "",
                  }}
                  onChange={(obj) => setMaxPayout(obj["value"])}
                  styles={customStyles}
                  required
                />
              </FormGroup>
            </div>
          </div>
          <div className="row justify-content-center m-1">
            <div
              className="col-md-3"
              style={{
                fontSize: "10px",
                textAlign: "left",
                paddingRight: "0px",
              }}
            >
              <Label>Long Start</Label>
              <DatePicker
                selected={longStartDate}
                dateFormat="yyyy/MM/dd"
                onChange={(date) => {
                  setLongStartDate(date);
                }}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                adjustDateOnChange
                placeholderText="Start Date"
              />
            </div>
            <div
              className="col-md-3"
              style={{
                fontSize: "10px",
                textAlign: "left",
                paddingRight: "0px",
              }}
            >
              <Label>Long End</Label>
              <DatePicker
                selected={longEndDate}
                dateFormat="yyyy/MM/dd"
                onChange={(date) => {
                  setLongEndDate(date);
                }}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                adjustDateOnChange
                placeholderText="End Date"
              />
            </div>
            {modal === "Bimodal" ? (
              <>
                <div
                  className="col-md-3"
                  style={{
                    fontSize: "10px",
                    textAlign: "left",
                    paddingRight: "0px",
                  }}
                >
                  <Label>Short Start</Label>
                  <DatePicker
                    selected={shortStartDate}
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => {
                      setShortStartDate(date);
                    }}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    adjustDateOnChange
                    placeholderText="Start Date"
                  />
                </div>
                <div
                  className="col-md-3"
                  style={{
                    fontSize: "10px",
                    textAlign: "left",
                    paddingRight: "0px",
                  }}
                >
                  <Label>Short End</Label>
                  <DatePicker
                    selected={shortEndDate}
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => {
                      setShortEndDate(date);
                    }}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    adjustDateOnChange
                    placeholderText="End Date"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="row justify-content-center mt-3">
            {Object.entries(monthsRange).map(([key, value]) => (
              <div
                className="col-md-5"
                style={{
                  fontSize: "11px",
                  color: "#0039E6",
                  fontWeight: "bold",
                }}
              >
                {key}: {value} Months
              </div>
            ))}{" "}
          </div>

          <div className="row justify-content-center mt-3">
            <div className="col-md-10" style={{ fontSize: "12px" }}>
              <Label>
                Sum Insured: <b>{sumInsured} USD</b>
              </Label>
              <Slider
                min={30}
                max={300}
                defaultValue={120}
                step={10}
                marks={{ 30: 30, 300: 300 }}
                onChange={(e) => {
                  setSumInsured(e);
                }}
                trackStyle={{ backgroundColor: "blue", height: 3 }}
                handleStyle={{
                  borderColor: "red",
                  height: 12,
                  width: 12,
                  marginLeft: -5,
                  marginTop: -5,
                  backgroundColor: "black",
                }}
                railStyle={{ backgroundColor: "red", height: 3 }}
              />
            </div>
          </div>
          <div className="row justify-content-center m-1 mt-3">
            <div className="col-md-5" style={{ fontSize: "10px" }}>
              <Label>Deductible %</Label>
              <input
                type="text"
                className="form-control"
                value={deductible !== null ? deductible : ''}
                onChange={handleDeductibleChange}
                required
                style={{ minHeight: 28, fontSize: '10px', textAlign: "center" }}
              />
            </div>{" "}
            <div className="col-md-5" style={{ fontSize: "10px" }}>
              <Label>Target Loss Ratio %</Label>
              <input
                type="number"
                min="1"
                className="form-control"
                value={commercialLoading !== null ? commercialLoading : ''}
                onChange={handleLoadingChange}
                required
                style={{ minHeight: 28, fontSize: '10px', textAlign: "center" }}
              />
            </div>{" "}
          </div>

          <div>
            <Button
              onClick={handleLoadingDeduction}
              className="mt-3 shadow"
              style={{
                color: "#fff",
                borderColor: "#0039E6",
                backgroundColor: "#0039E6",
                fontSize: "12px",
              }}
            >
              Calculate
            </Button>

          </div>

          {summaryExit.length !== 0 && summaryTrigger.length !== 0 ? (
            <>
              <div className="row justify-content-center mt-4" id="summary" style={{ display: "flex", justifyContent: "center" }}>
                <div className="col-md-6">
                  <CardTitle tag="h6" style={{ color: "#0039E6", fontSize: '16px' }}>
                    Summary
                    <CSVLink
                      data={summaryDownload}
                      filename={stateName + "_" + clusterNumber + "_Summary.csv"}
                    >
                      <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "20px",
                          width: "20px",
                          justifyContent: "center",
                          display: "inline-flex",
                          margin: '10px',
                        }}
                      >
                        <AiOutlineDownload
                          size={12}
                          style={{ color: "#fff", marginTop: "4px" }}
                        />
                      </div>
                    </CSVLink>
                  </CardTitle>
                </div>
              </div>

              <div className="row justify-content-center mt-2">
                {summaryTrigger.length !== 0 ? (
                  <>
                    <div className="col-md-5">
                      <Card
                        body
                        style={{
                          backgroundColor: "#fff",
                          borderColor: "#f5f5f5",
                          padding: "5px",
                        }}
                      >
                        <div
                          className="row justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          <div className="col-md-12">
                            <div className="row justify-content-center">
                              <div className="col-md-2">
                                <FiCheck
                                  size={20}
                                  style={{ color: "#0039E6" }}
                                />
                              </div>
                              <div className="col-md-6">Trigger</div>
                            </div>
                          </div>
                          {Object.entries(summaryTrigger).map(
                            ([key, value]) => (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "11px",
                                  color: "#0039E6",
                                }}
                              >
                                {key}: {value}
                              </span>
                            )
                          )}{" "}
                        </div>
                      </Card>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {summaryExit.length !== 0 ? (
                  <>
                    <div className="col-md-5">
                      <Card
                        body
                        style={{
                          backgroundColor: "#fff",
                          borderColor: "#f5f5f5",
                          padding: "5px",
                        }}
                      >
                        <div
                          className="row justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          <div className="col-md-12">
                            <div className="row justify-content-center">
                              <div className="col-md-2">
                                <FiCheck
                                  size={20}
                                  style={{ color: "#0039E6" }}
                                />
                              </div>
                              <div className="col-md-4">Exit</div>
                            </div>
                          </div>
                          {Object.entries(summaryExit).map(([key, value]) => (
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "11px",
                                color: "#0039E6",
                              }}
                            >
                              {key}: {value}
                            </span>
                          ))}{" "}
                        </div>
                      </Card>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Button
                onClick={downloadPdfDocument}
                className="mt-3 shadow"
                style={{
                  color: "#fff",
                  borderColor: "#0039E6",
                  backgroundColor: "#0039E6",
                  fontSize: "12px",
                }}
              >
                Download Termsheet
              </Button>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-9" style={{ overflowY: 'scroll', height: "85vh" }}>

          {clusterNumber !== null ? (
            loading && payoutAnalysis.length !== 0 && bulkPayoutAnalysis.length !== 0 ? (

              <>
                <Card
                  body
                  className="mt-1"
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "#f5f5f5",
                    padding: "2px",
                  }}
                >
                  <CardBody>
                    <div className="row justify-content-between">
                      <div className="col-md-6 mt-5">
                        <div style={{ display: "flex", justifyContent: "left" }}>
                          <CardTitle tag="h6" style={{ color: "#0039E6", fontSize: '16px' }}>
                            No Of Wards : {numberOfWards} {" "}
                            
                            <div
                              className="shadow m-1 mb-3"
                              style={{
                                borderRadius: "50%",
                                borderColor: "#0039E6",
                                backgroundColor: "#0039E6",
                                height: "20px",
                                width: "20px",
                                margin: '10px',
                                justifyContent: "center",
                                display: "inline-flex",
                                cursor: "pointer",
                              }}
                              onClick={handleClickOpen}
                            >
                              <AiOutlineDownload
                                size={12}
                                style={{ color: "#fff", marginTop: "4px" }}
                              />
                            </div>
                            <SimpleDialog
                              selectedValue={selectedValue}
                              open={open}
                              onClose={handleClose}
                            />
                          </CardTitle>
                        </div>

                        <img
                          style={{
                            width: "100%",
                            margin: "0 0 35px 0px",
                          }}
                          src={clusterImage}
                          alt="Card cap"
                        />
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <CardTitle tag="h6" style={{ color: "#0039E6", fontSize: '16px' }}>
                            Historical Payout Analysis
                            <CSVLink
                              data={payoutAnalysis["csvData"]}
                              filename={
                                stateName +
                                "_" +
                                clusterNumber +
                                "_PayoutAnalysis.csv"
                              }
                            >
                              <div
                                className="shadow mt-3"
                                style={{
                                  borderRadius: "50%",
                                  margin: "10px",
                                  borderColor: "#0039E6",
                                  backgroundColor: "#0039E6",
                                  height: "20px",
                                  width: "20px",
                                  justifyContent: "center",
                                  display: "inline-flex",
                                }}
                              >
                                <AiOutlineDownload
                                  size={12}
                                  style={{ color: "#fff", marginTop: "4px" }}
                                />
                              </div>
                            </CSVLink>
                          </CardTitle>
                          <CardTitle tag="h6" style={{ color: "#0039E6", fontSize: '16px',cursor: 'pointer' }}>
                            Region Payout
                            <div
                              className="shadow mt-3"
                              style={{
                                borderRadius: "50%",
                                margin: "10px",
                                borderColor: "#0039E6",
                                backgroundColor: "#0039E6",
                                height: "20px",
                                width: "20px",
                                justifyContent: "center",
                                display: "inline-flex",
                              }}
                              onClick={handleRegionPayoutDownload}
                            >
                              <AiOutlineDownload
                                size={12}
                                style={{ color: "#fff", marginTop: "4px" }}
                              />
                            </div>
                            {/*</CSVLink>*/}
                          </CardTitle>

                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="row">
                          {seasonWeightage.length !== 0 ? (
                            <>
                              <div className="col-md-5">
                                <Card
                                  body
                                  className="shadow"
                                  style={{
                                    borderColor: "#0039E6",
                                    marginRight: "10px",
                                    padding: "0px",
                                    color: "#0039E6",
                                  }}
                                >
                                  <div className="row justify-content-center">
                                    <div className="col-md-12">
                                      <div className="row justify-content-center">
                                        <div
                                          className="col-md-12 mt-1"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Season Weightage
                                        </div>
                                      </div>
                                      {Object.entries(seasonWeightage).map(
                                        ([key, value]) => (
                                          <div
                                            className="row justify-content-center mt-1"
                                            style={{
                                              fontSize: "11px",
                                              color: "#0039E6",
                                            }}
                                          >
                                            {key}: {value} %
                                          </div>
                                        )
                                      )}{" "}
                                    </div>
                                  </div>
                                </Card>
                                <Pallete state={stateName} />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-md-5">
                                <Card
                                  body
                                  className="shadow"
                                  style={{
                                    borderColor: "#0039E6",
                                    marginRight: "10px",
                                    padding: "0px",
                                    color: "#0039E6",
                                  }}
                                >
                                  <div className="row justify-content-center">
                                    <div className="col-md-12">
                                      <div className="row justify-content-center">
                                        <div
                                          className="col-md-12 mt-1"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Season Weightage
                                        </div>
                                      </div>
                                      <div
                                        className="row justify-content-center mt-1"
                                        style={{
                                          fontSize: "11px",
                                          color: "#0039E6",
                                        }}
                                      >
                                        Long Rains: 100 %
                                      </div>
                                      <div
                                        className="row justify-content-center mt-1"
                                        style={{
                                          fontSize: "11px",
                                          color: "#0039E6",
                                        }}
                                      >
                                        Short Rains: 0 %
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                                <Pallete state={stateName} />
                              </div>
                            </>
                          )}

                          <div className="col-md-5">
                            <Card
                              body
                              className="shadow"
                              style={{
                                color: "#fff",
                                borderColor: "#0039E6",
                                marginRight: "10px",
                                backgroundColor: "#0039E6",
                                padding: "0px",
                              }}
                            >
                              <div className="row justify-content-center">
                                <div className="col-md-12">
                                  <div className="row justify-content-center">
                                    <div className="col-md-2">
                                      <FiCheck
                                        size={20}
                                        style={{ color: "#fff" }}
                                      />
                                    </div>
                                    <div
                                      className="col-md-8 mt-1"
                                      style={{
                                        color: "#fff",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Premium Rate
                                    </div>
                                  </div>
                                </div>

                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                  }}
                                >
                                  {payoutAnalysis["premium"]}%
                                </span>
                              </div>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-start">

                      {modal === "Bimodal" ? (
                        <div id="chart" className="col-md-7">
                          <StackedColumn data={payoutAnalysis} />
                        </div>
                      ) : (
                        <>
                          <h6>Long Rains</h6>
                          <div id="chart" className="col-md-7">
                            <Column
                              data={payoutAnalysis["Long Rains"]}
                              modal={modal}
                              type={"Pricing"}
                              yAxis={"Payout (%)"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </>
            ) :(
              payoutAnalysis.length === 0 || bulkPayoutAnalysis.length === 0 ?
                (<CardTitle style={{ textAlign: 'left', border: '1px solid #D3D3D3', padding: '10px' ,margin:'10%'}}>
                  <p>NDVI data is not available, possible reason can be due to :</p>
                  <p> 1. Grassland and Shrubland in this ward is less than 15% of total area.</p>
                  <p> 2. Due to modis ndvi resolution problem.</p>
                  <p> 3. The data available is less than 75% of historical data.</p>
                </CardTitle>
                ) : (
                  <div className="mt-5">
                    <Spinner color="dark" />
                  </div>)
            )
          ) : (<>
            <h6 className="mt-5 mr-5">Select Inputs</h6>
            {loading === false || clusterNumber != null ? (
              <div className="row">
                <div className="col-md-8">
                  <img
                    style={{
                      width: "550px",
                      margin: "35px 0 35px 35px",
                    }}
                    src={clusterImage}
                    alt="Card cap"
                  />
                </div>
                <div className="col-md-2">
                  <Pallete state={stateName} />
                </div>
              </div>
            ) : ('')}
          </>
          )}
        </div>
      </div>
    </>
  );
};
