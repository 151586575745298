import React from "react";
import { CardFooter, CardImg } from "reactstrap";
export const Footer = () => {
  return (
    <>
      <CardFooter
        style={{
          width: "100%",
          backgroundColor: "#0039E6",
          height: "40px",
          marginTop: "-10px",
          color: "#fff",
          fontSize: "12px",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-md-3 mt-2">
            Powered by
            <CardImg
              style={{
                width: "25px",
                marginLeft: "5px",
              }}
              src="white.png"
              alt="Card image cap"
            />
          </div>
        </div>
      </CardFooter>
    </>
  );
};
