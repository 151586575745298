const awsmobile = {
  "aws_project_region": "ap-south-1",
  "aws_cognito_identity_pool_id": "ap-south-1:83671fc7-22b7-485b-be9e-eaca93240f1f",
  "aws_cognito_region": "ap-south-1",
  "aws_user_pools_id": "ap-south-1_QPLBjNcuA",
  "aws_user_pools_web_client_id": "385qkcq4svb7irek66bqj6277b",
  "oauth": {},
  // "aws_mobile_analytics_app_region": "ap-south-1",
  // "aws_mobile_analytics_app_id": "9b7ffa0f54f74ef7856b33d0daa48014"
};
export default awsmobile;