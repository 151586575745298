import { combineReducers } from "redux";
import { changeStateNameReducer } from "./reducers/changeStateNameReducer";
import { changeDistrictNameReducer } from "./reducers/changeDistrictNameReducer";
import { changeWardNameReducer } from "./reducers/changeWardNameReducer";
import { changeClusterNumberReducer } from "./reducers/changeClusterNumberReducer";
import { changeClusterNumbersReducer } from "./reducers/changeClusterNumbersReducer";

const rootReducer = combineReducers({
  stateName: changeStateNameReducer,
  districtName: changeDistrictNameReducer,
  wardName: changeWardNameReducer,
  clusterNumber: changeClusterNumberReducer,
  clusterNumbers:changeClusterNumbersReducer,
});

export default rootReducer;
